import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth, User, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import "../css/Login.css";

interface LoginProps {
    auth: Auth;
}

const Login : React.FC<LoginProps> = (props) => {
    
    const validateEmail = (email: string): string | undefined => {
        if(!email.includes("@")) return "Please enter a valid email";
        return undefined;
    }

    const validatePassword = (password: string): string | undefined => {
        if(password.length < 9) return "Password must be at least 9 characters long";
        return undefined;
    }

    const confirmPassword = (password:string) : string | undefined => {
        if(password !== inputs.password) return "Passwords do not match";
        return undefined;
    }

    const validate = (newInputs: Inputs): Errors => {
        const newErrors: Errors = {}
        const emailError = validateEmail(newInputs.email);
        const passError = validatePassword(newInputs.password);
        const confirmPassError = isSignup ? confirmPassword(newInputs.confirmPassword) : undefined;
        if(emailError) newErrors.email = emailError;
        if(passError) newErrors.password = passError;
        if(confirmPassError) newErrors.confirmPassword = confirmPassError;

        return newErrors
    }

    const OnAuthStateChanged = (user: User | null) => {
        if(user != null) navigate("/");
    };

    const loginForm = () => {
        return (
            <div className="loginForm">
                <form noValidate>
                <div className="form-group mb-3">
                <label className="form-label">Email </label>
                    <input type="email" name="email" className="form-control" onChange={
                        event=> {
                            setInputs({...inputs, email: event.target.value})
                            setErrors(validate({...inputs, email: event.target.value}))
                        }
                    }
                    onBlur={() => setSubmitted({...submitted, email: true})}
                    value={inputs.email}
                    />
                    {errors.email && submitted.email ? <p className="alert alert-danger">{errors.email}</p> : null}
                
                </div>
                <div className="form-group mb-3">
                <label className="form-label">Password </label>
                    <input type="password" name="password" className="form-control" onChange={
                        event=> {
                            setInputs({...inputs, password: event.target.value})
                            setErrors(validate({...inputs, password: event.target.value}))
                        }
                    }
                    onBlur={() => setSubmitted({...submitted, password: true})}
                    value={inputs.password}
                    />
                    {errors.password && submitted.password ? <p className="alert alert-danger">{errors.password}</p> : null}
                </div>
                <div className="text-center">
                    <button className="btn btn-primary" onClick={event => {
                        event.preventDefault()
                        if(Object.keys(errors).length === 0)
                        {
                            signInWithEmailAndPassword(props.auth, inputs.email, inputs.password).catch((e) => {alert(e.target.value)});
                        }
                    }}>
                        Login
                    </button></div>
                </form>
            </div>
        );
    }

    const signupForm = () => {
        return (
            <div className="signupForm">
                <form noValidate>
                <div className="form-group mb-3">
                <label className="form-label">Email </label>
                    <input type="email" name="email" className="form-control" onChange={
                        event=> {
                            setInputs({...inputs, email: event.target.value})
                            setErrors(validate({...inputs, email: event.target.value}))
                        }
                    }
                    onBlur={() => setSubmitted({...submitted, email: true})}
                    value={inputs.email}
                    />
                    {errors.email && submitted.email ? <p className="alert alert-danger">{errors.email}</p> : null}
                
                </div>
                <div className="form-group mb-3">
                <label className="form-label">Password </label>
                    <input type="password" name="password" className="form-control" onChange={
                        event=> {
                            setInputs({...inputs, password: event.target.value})
                            setErrors(validate({...inputs, password: event.target.value}))
                        }
                    }
                    onBlur={() => setSubmitted({...submitted, password: true})}
                    value={inputs.password}
                    />
                    {errors.password && submitted.password ? <p className="alert alert-danger">{errors.password}</p> : null}
                </div>
                <div className="form-group mb-3">
                <label className="form-label">Confirm Password </label>
                    <input type="confirm-password" name="confirm-password" className="form-control" onChange={
                        event=> {
                            setInputs({...inputs, confirmPassword: event.target.value})
                            setErrors(validate({...inputs, confirmPassword: event.target.value}))
                        }
                    }
                    onBlur={() => setSubmitted({...submitted, confirmPassword: true})}
                    value={inputs.confirmPassword}
                    />
                    {errors.confirmPassword && submitted.confirmPassword ? <p className="alert alert-danger">{errors.confirmPassword}</p> : null}
                </div>
                <div className="text-center">
                    <button className="btn btn-primary" onClick={event => {
                        event.preventDefault()
                        if(Object.keys(errors).length === 0)
                        {
                            createUserWithEmailAndPassword(props.auth, inputs.email, inputs.password).catch((e) => {alert(e.target.value)});
                        }
                    }}>
                        Sign Up
                    </button></div>
                </form>
            </div>
        );
    }
    
    const navigate = useNavigate();
    const [isSignup, setIsSignUp] = useState(false);
    
    type Inputs = {email: string; password: string, confirmPassword: string}
    
    type Errors = Partial<Record<keyof Inputs, string>>
    type Submitted = Partial<Record<keyof Inputs, boolean>>
    const [inputs, setInputs] = useState<Inputs>({email: "", password: "", confirmPassword: ""})
    const [errors, setErrors] = useState<Errors>(validate(inputs))
    const [submitted, setSubmitted] = useState<Submitted>({})
    
    const googleProvider = new GoogleAuthProvider();
    const signInWithGoogle = () => {
        return signInWithPopup(props.auth, googleProvider);
    }

    props.auth.onAuthStateChanged(OnAuthStateChanged);

    return (
        <div className="container bg-dark text-light">
            <header>
                <h1>{isSignup ? "Sign Up" : "Login"}</h1>
            </header>
            {isSignup ? signupForm() : loginForm()}
            <div>
            <button className="google-btn" onClick={signInWithGoogle}>
                <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" />
                    <span>Continue with Google</span>
                </button>
            </div>
            <div>
                <p className="text-center py-5"><button onClick= { () => setIsSignUp(!isSignup)}>{isSignup ? "Already have an account? Login" : "Don't have an account? Register"}</button></p>
            </div>
        </div>

    );
}

export default Login;