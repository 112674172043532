import React, { useState } from "react";
import { useNavigate  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth, User } from "firebase/auth";

interface HomeProps {
    auth: Auth;
}

const Home : React.FC<HomeProps> = (props) => {

    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(false);

    const OnAuthStateChanged = (user: User | null) => {
        setLoggedIn(user != null);
    };

    props.auth.onAuthStateChanged(OnAuthStateChanged);

    return (
        <div className="container bg-dark text-light">
            <header>
                <h1>Home</h1>
            </header>
            <h2>{loggedIn ? "You are logged in as " + props.auth.currentUser?.email : "You are not logged in."}</h2>
            { loggedIn ? <button className="button bg-dark text-light" onClick={() => props.auth.signOut()}>Log Out</button> : <button className="button bg-dark text-light" onClick={() => navigate("/login")}>Log In</button>}
        </div>

    );
}

export default Home;