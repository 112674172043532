import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/App.css';

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import Home from './components/Home.tsx';
import Login from './components/Login.tsx';

const firebaseConfig = {
  apiKey: "AIzaSyB1YDb3q5L7rhN0XM8yIjkh8FGmCeeqBWY",
  authDomain: "mywebtoonlist-224dc.firebaseapp.com",
  projectId: "mywebtoonlist-224dc",
  storageBucket: "mywebtoonlist-224dc.firebasestorage.app",
  messagingSenderId: "378573343247",
  appId: "1:378573343247:web:e484f56405816aae0a717a",
  measurementId: "G-XBPYHSQ43J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions();
// const addMessage = httpsCallable(functions, 'addMessage');
// addMessage({data: data}).then(); // should do async

function App() {

  if(app) console.log("App Init");
  if(auth) console.log("Auth init");

  return (
    <div className="App bg-dark text-light min-vh-100">
      <Router>
        <Routes>
          <Route path="/" element={<Home auth = {auth}/>} />
          <Route path="/login" element={<Login auth ={auth}/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
